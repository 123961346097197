@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body, button, input, select, optgroup, textarea{ color: #1C407F; font-size: 16px; word-wrap: break-word; font-family: "Poppins", sans-serif !important; font-style: normal; font-weight: 400; line-height: 1.5; margin: 0; background-color: #f0f7fd;}
h1, h2, h3, h4, h5, h6, p{ font-family: "Poppins", sans-serif !important;}
code { font-family: "Poppins", sans-serif;}
img { max-width:100%; vertical-align:middle; border:none;}
*, ::before, ::after{ box-sizing: border-box;}
div.container{ max-width: 1360px; padding: 0 20px;}

button.button1, a.button1{ background-color: rgb(251, 170, 26); border-radius: 50px; text-transform: uppercase; cursor: pointer; padding: 14px 40px; color: white; font-weight: 600; font-family: "Poppins", sans-serif; font-size: 16px; box-shadow: none;}
button.button1:hover, a.button1:hover{ background-color: #1C407F; box-shadow: none;}
button.button2, a.button2{ background-color: #23C860; border-radius: 60px; text-transform: none; cursor: pointer; padding: 15px 40px; color: white; font-weight: 600; font-family: "Poppins", sans-serif; font-size: 17px; box-shadow: none; display: inline-block; text-align: center; line-height: normal; min-width: 285px;}
button.button2:hover, a.button2:hover{ background-color: #1C407F; box-shadow: none;}

button.button3, a.button3{ border: 1px solid #23C860; color: #23C860; border-radius: 40px; min-width: 285px; height: 55px; display: flex; justify-content: center; align-items: center; text-align: center; font-size: 17px; font-weight: 600; background: none; box-shadow: none; padding: 0; text-transform: none; letter-spacing: 0; text-decoration: none;}
button.button3:hover, a.button3:hover{ background: #23C860; color: #fff; box-shadow: none;}

.login_img{ position: relative; height: 100%;}
.login_img::before{ content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-image: linear-gradient(to top, rgba(16, 63, 131, 0.69), rgba(16, 63, 131, 0.69));}
.login_img img{ width: 100%; height: 100%; object-fit: cover;}
.login_content{ position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; flex-direction: column; padding: 8rem;}
.login_content h2{ color: white; font-weight: 700; font-size: 48px; margin: 0;}
.login_content p{ font-weight: 300; color: white; margin: 0;}

.login_right .lrTab{ background-color: #ebe9eb; border-radius: 50px; padding: 3px; gap: 5px; display: flex;}
.login_right .lrTab button{ border-radius: 50px; text-transform: uppercase; font-size: 1rem; font-weight: bold; padding: 8px 26px; border: 0; color: #1C407F; background-color: transparent;}
.login_right .lrTab button.Mui-selected{ background-color: #fff;}
.login_right{ display: flex; align-items: center; justify-content: center; padding: 30px;}
.login_form_inner{ max-width: 525px; margin: auto; gap: 1rem; display: flex; flex-wrap: wrap;}
.login_form_inner .MuiTextField-root{ margin: 0;}
.login_form_inner .firstName{ width: calc(50% - 8px);}
.login_form_inner .MuiInputLabel-formControl{ color: rgb(15, 63, 131);}
.login_form_inner .MuiInputLabel-formControl.Mui-focused{ color: rgb(15, 63, 131);}
.login_form_inner .MuiInputBase-formControl{ background-color: rgb(249, 250, 250);}
.login_form_inner .MuiInputBase-formControl input{ box-shadow: none; color: #1C407F;}
.login_form_inner fieldset{ border-color: rgb(157, 177, 206);}
.login_form_inner .MuiInputBase-formControl.Mui-focused fieldset{ border-color: #1C407F;}
.login_remindme .MuiCheckbox-root{ padding-top: 0; padding-bottom: 0; color: #1C407F;}
.login_remindme .MuiCheckbox-root.Mui-checked{ color: #1C407F;}
.login_form_inner .forgotpassword{ text-align: center; width: 100%;}
.login_form_inner .forgotpassword a{ color: rgb(15, 63, 131); text-decoration: none;}
.login_right .fyp_txt{ max-width: 525px; margin: 0 auto 35px; text-align: center;}

.navbar{ padding: 30px 0;}
.mainMenu .MuiToolbar-gutters{ padding: 0 50px 0 0; min-height: inherit;}
.mainMenu .mainMenu_inner{ display: flex; gap: 57px;}
.mainMenu button{ font-weight: 400; padding: 0; letter-spacing: 0; line-height: normal; border-radius: 0;}
.mainMenu a{ font-weight: 400; padding: 0; letter-spacing: 0; line-height: normal; border-radius: 0; color: #1C407F; text-transform: uppercase; text-decoration: none;}
.search_bar{ position: relative;}
.search_bar .search_fld{ position: absolute; width: 30vw; right: -10px; top: -11px; pointer-events: none;}
.search_bar .search_fld.searchOpen{ pointer-events: auto;}
.search_bar form fieldset{ border: 0; padding: 0;}
.search_bar form input{ height: auto; padding: 13px 50px 13px 15px; box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1); font-family: "Poppins", sans-serif; font-size: 14px;}
.search_bar form button{ border-radius: 0; min-width: inherit; width: auto; padding: 0; background: none;}
.search_bar form button:hover{ background: none;}

.result_box{ border-radius: 4px; width: 30vw; top: 60px; z-index: 10; padding: 0px; background-color: rgb(255, 255, 255); color: rgb(15, 63, 131); position: absolute; right: -10px; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px; transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);}
.result_box .result_count{ padding: 10px 16px; display: flex; align-items: center; justify-content: space-between; width: 100%; background-color: rgb(220, 240, 255);}
.result_box .result_count button{ color: #1C407F;}
.result_box ul{ margin: 0; padding: 0; list-style: none;}
.result_box ul li a{ padding: 1rem; display: flex; gap: .5rem; text-decoration: none; color: #1C407F;}
.result_box ul li:nth-child(even) a{ background-color: #ddf0ff;}
.result_box ul li a:hover{ background-color: #eef7fe;}
.result_box ul li a .result_prdimg{ max-width: 90px;}
.result_box ul li a .result_prdimg img{ border: 1px solid #ebe9eb; width: 100%; border-radius: .375rem; display: block;}
.result_box ul li a .result_prdcontent{ display: flex; flex-direction: column; width: 100%;}
.result_box ul li a .result_prdcontent h2{ font-size: 16px; color: #1C407F; font-weight: 600;}
.result_box ul li a .result_viewbtn{ align-self: center;}
.result_box ul li a .result_viewbtn .button1{ font-size: 14px; min-width: 96px; padding: 13px;}

.menu_toggle{ padding-left: 35px;}
.menu_toggle button{ padding: 9px 31px; text-transform: none; font-size: 16px; font-weight: 400;}
.menu_toggle button svg{ margin-left: 9px;}
.header_end{ display: flex; justify-content: flex-end;}
.header_end .account_icon{ display: flex; flex-direction: column; align-items: center; font-size: 14px; text-align: center; text-decoration: none; color: #1C407F; position: relative; cursor: pointer; margin-left: 28px;}
/* .header_end .account_icon + .account_icon{ margin-left: 15px;} */
.header_end .account_icon svg{ max-width: 32px;}
.header_end .account_icon .cart_count{ display: flex; align-items: center; justify-content: center; border-radius: 30px; background-color: #fbab1b; font-size: 12px; color: #fff; font-weight: 400; width: 20px; height: 20px; position: absolute; right: -4px; top: -11px;}
.header_end .account_icon.menu_icon{ display: none;}

.footer_wrap .footer_col{ max-width: inherit; flex-basis: inherit;}
.footer_wrap .footer_logo img{ width: auto; height: auto;}
.footer_wrap h3{ font-family: "Poppins", sans-serif; margin-bottom: 0; font-size: 12px; font-weight: 700;}
.footer_wrap ul{ padding: 0;}
.footer_wrap ul li{ padding: 7px 0 0 ;}
.footer_wrap ul li a{ font-size: 12px; text-decoration: none; color: rgb(15 63 131); text-decoration: underline;}
.footer_wrap ul li a:hover{ text-decoration: none;}
.footer_legal p{ margin: 0; font-size: 12px; text-align: right;}
.footer_legal p strong{ font-weight: 700;}
.footer_legal p a{ color: #1C407F; text-decoration: underline;}
.footer_legal p a:hover{ text-decoration: none;}
.footer_brands{ display: flex; justify-content: flex-end; gap: 10px; margin-top: 30px;}
.footer_brands img{ max-height: 22px; width: auto; height: auto;}
.partner_logo{ text-align: right; padding-top: 20px;}
.partner_logo img{ width: auto; height: auto;}

.footer_infoBx{ margin-top: 20px;}
.footer_infoBx p{ margin: 0; font-weight: 600; font-size: 12px;}
.footer_infoBx p:last-of-type{ margin: 0;}
.footer_infoBx p strong{ font-weight: 600;}
.footer_infoBx p a{ text-decoration: none; color: #178d3c;}
.footer_infoBx p a span{ color: #1C407F;}

.footer_content{ background-color: #f0f7fd; margin-top: 30px; padding: 23px 0;}

.top_bar{ max-width: 1536px; margin: auto; padding: 0 24px; position: relative;}
.top_bar .MuiContainer-root{ display: flex; align-items: center; justify-content: space-between; padding: 15px 20px; border-radius: 3px;}
.top_bar p{ color: #fff; letter-spacing: 0; align-items: center;}
.top_bar p svg{ margin-right: 10px;}
.top_bar p b{ font-weight: 600;}

.ndBanner{ padding-top: 10px;}
.ndBanner_content{ align-self: center;}
.ndBanner_content h1{ font-size: 40px; font-weight: 600; margin-bottom: 10px;}
.ndBanner_content h4{ font-size: 22px; font-weight: 600;}
.ndBanner_content ul{ margin: 7px 0 21px; padding: 0;}
.ndBanner_content ul li{ padding: 0;}
.nbBanner_img{ text-align: right;}
.nbBanner_img img{ width: auto; height: auto;}
.nbBanner_img_col{ position: relative;}
.nbBanner_img_col ul{ margin: 0; padding: 0; list-style: none; width: 327px; position: absolute; left: 15%; bottom: 30%;}
.nbBanner_img_col ul li{ background: rgba(35, 200, 96, 0.7); padding: 4px 11px; border-radius: 16px; font-size: 13px; display: flex; align-items: center; color: #fff;}
.nbBanner_img_col ul li:last-child{ background: rgba(234, 168, 59, 0.7); position: relative; top: 14px; left: -90px;}
.nbBanner_img_col ul li span{ font-size: 40px; font-weight: 600; margin-right: 10px;}

.shipping_wrap{ padding: 35px 0;}
.shipping_block{ display: flex; justify-content: space-between;}
.shipping_block .MuiGrid-item{ display: inline-flex; align-items: center; margin: 0; max-width: 245px; color: #fff; font-weight: 600;}
.shipping_block .MuiGrid-item span{ margin-right: 10px; background-color: #fff; width: 60px; height: 60px; border-radius: 50px; flex-shrink: 0; display: flex; align-items: center; justify-content: center;}
.shipping_block .MuiGrid-item span img{ width: auto; height: auto;}
.rating_block.MuiGrid-item{ max-width: 872px; margin: auto; padding: 56px 20px; text-align: center;}
.rating_block .rating_star{ font-size: 2rem;}
.rating_block .rating_star .MuiRating-iconEmpty{ color: #F0AE43;}
.MuiRating-decimal .MuiRating-iconEmpty{ color: #F0AE43;}
.rating_block.MuiGrid-item h1{ font-size: 30px; font-weight: 700; text-transform: uppercase; letter-spacing: 0;}
.rating_block.MuiGrid-item p{ letter-spacing: 0;}

.home_services{ gap: 1rem; padding: 35px 0;}
.home_services .MuiGrid-item{ max-width: calc(33.33% - 11px); flex-basis: 100%; border-radius: 8px;}
.home_services .MuiGrid-item a{ padding: 16px; display: flex; align-items: center; gap: 0.5rem; text-decoration: none; font-weight: 700; color: #1C407F; text-transform: uppercase;}
.home_services .MuiGrid-item a img{ width: 48px;}
.home_services .MuiGrid-item a img.catRightImage{ margin-left: auto;}
.home_services .MuiGrid-item a.category_title{ padding-bottom: 0;}
.home_services .MuiGrid-item a.category_title h2{ font-size: 16px; font-weight: 700; text-transform: none; letter-spacing: 0;}
.home_services .MuiGrid-item a.category_title + a{ font-weight: 400; text-transform: none;}

.spc_wrap{ padding: 32px 0;}
.spc_inner{ align-items: center;}
.spc_wrap .spc_img img{ max-width: 66.66%; width: auto; height: auto;}
.spc_wrap .spc_content ul{ margin: 0; padding: 0;}
.spc_wrap .spc_content ul li .MuiTimelineDot-root{ border: 0; background-color: #fbab1b; padding: 0; margin: 0; width: 40px; height: 40px; box-shadow: none; justify-content: center; align-items: center; font-weight: 700;}
.spc_wrap .spc_content ul li .MuiTimelineConnector-root{ width: 4px;}

.packaging_wrap{ padding: 64px 0;}
.packaging_wrap .packaging_inner{ flex-wrap: nowrap; gap: 4rem;}
.packaging_content h5{ font-size: 18px; margin-bottom: 16px;}
.packaging_content p{ font-size: 14px; margin-bottom: 16px;}
.packaging_content ul{ padding: 0;}
.packaging_content ul li{ padding: 0;}
.packaging_content ul li div{ min-width: inherit; width: auto; height: auto; margin: 0;}
.packaging_content ul li svg{ width: 14px;}
.packaging_content ul li .MuiListItemText-root{ margin-left: 8px;}

.faq_home{ padding: 100px 0;}
.faq_home_title{ font-size: 30px !important;}
.faq_home .faq_accordion{ padding-top: 45px; gap: 20px;}
.faq_home_items{ width: 780px; margin: auto !important;}
.faqHome_innerItem{ padding: 10px 24px;}
.faqHome_innerItem #panel2-header{ flex-direction: row-reverse;}
.faqHome_innerItem #panel2-header h5{ font-size: 18px; font-weight: 600; color: #1C407F; margin-left: 15px;}
.faq_home_items .faqhome_content{ font-size: 18px; color: #1C407F; padding: 0 0 0 45px;}
.faq_home_items .faqhome_content p{ margin-top: 0;}
.faq_home .faqBtn{ margin-top: 55px;}
.faq_home .faqBtn a{ border: 1px solid #23C860; color: #23C860; border-radius: 40px; min-width: 340px; height: 55px; display: inline-flex; justify-content: center; align-items: center; text-align: center; font-size: 17px; font-weight: 600; background: none; box-shadow: none; padding: 0 30px; text-transform: none; letter-spacing: 0; text-decoration: none;}
.faq_home .faqBtn a:hover{ background: #23C860; color: #fff; box-shadow: none;}

.ocs_wrap{ padding: 64px 0 42px;}
.ocs_title{ font-size: 30px !important; margin: 32px 0 !important;}
.ocs_wrap .navigation-wrapper{ padding: 50px;}
.ocs_wrap .keen-slider .keen-slider__slide{ background-color: #fff; padding: 24px; border-radius: 8px; box-sizing: border-box; display: flex;
flex-direction: column; gap: 1.5rem;}
.ocs_wrap .keen-slider .keen-slider__slide .MuiRating-root{ font-size: 32px; gap: 1rem;}
.ocs_wrap .keen-slider .keen-slider__slide p{ letter-spacing: 0;}

.ksDots{ display: flex; padding: 20px 0 0; justify-content: center;}
.ksDot{ border: none; width: 8px; height: 8px; background: #1C407F; border-radius: 50%; margin: 0 4px; cursor: pointer; opacity: 0.2; padding: 0;}
.ksDot:focus{ outline: none;}
.ksDot.active{ background: #fbab1b; opacity: 1;}

.customer_service{ padding-top: 32px; gap: 2.5rem; flex-wrap: nowrap !important; align-items: center;}
.customer_service h3{ font-size: 24px; margin-bottom: 16px;}
.customer_service .csb{ display: flex; flex-wrap: wrap; padding-top: 32px;}
.customer_service .customer_service_bx{ width: 25%; display: flex; flex-direction: column; align-items: center; font-weight: 500;}
.customer_service .customer_service_bx img{ width: 80px; height: 80px; object-fit: contain; margin-bottom: 8px;}

.menuDrawer{ width: 600px; padding: 16px;}
.menuDrawer .menuCloseBtn{ color: #1C407F;}
.menuDrawer ul li{ padding: 13px 0;}
.menuDrawer ul li a{ text-decoration: none; align-items: center;}
.menuDrawer ul li .menuTxt span{ font-weight: 700;}
.menuDrawer ul li .menuPlusIcon{ min-width: inherit;}
.menuDrawer ul li ul{ display: none; padding-left: 8px; padding-bottom: 0;}
.menuDrawer ul li ul.showSubMenu{ display: block;}
.menuDrawer ul li ul.showSubMenu li{ border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding: 16px 0;}
.menuDrawer ul li ul.showSubMenu li:last-of-type{ border-bottom: 0;}
.menuDrawer ul li ul.showSubMenu li a{ color: #1C407F; display: flex;}
.menuDrawer ul li ul.showSubMenu li a svg{ margin-right: 12px;}
.menuDrawer a{ text-decoration: none; align-items: center;}
.menuDrawer a p{font-weight: 700;}
/*Legal page css start*/
.legal_header{ background: url(../public/images/faq-bg.png) center center no-repeat; background-size: cover; padding: 40px 0;}
.legal_header h1{ font-size: 40px; font-weight: 700;}
.legal_content{ background: linear-gradient(rgb(246, 250, 255), rgb(255, 255, 255)); padding: 60px 0;}
.legal_content .verticalStaticPageTabs{ overflow: visible;}
.legal_content .verticalStaticPageTabs .MuiTabs-scroller{ overflow: visible !important;}
.legal_content .pageContent{ padding: 20px 40px; border-radius: 5px; box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1); background-color: #ffffff; margin-left: 30px; font-weight: 500;}
.legal_faq{ padding-left: 30px; gap: 30px; flex-wrap: wrap; display: flex;}
.legal_faq .legal_faq_inner{ box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;}
.legal_faq .legal_faq_inner .MuiAccordionSummary-root{ padding: 20px;}
.legal_faq .legal_faq_inner .MuiAccordionSummary-content{ margin: 0;}
.legal_faq .legal_faq_inner .MuiAccordionSummary-content p{ font-size: 18px; font-weight: 700; color: #1C407F;}
.legal_faq .legal_faq_inner .MuiAccordionSummary-expandIconWrapper{ color: #1C407F;}
.legal_faq .legal_faq_inner .MuiAccordionSummary-root:hover .MuiAccordionSummary-content p, .legal_faq .legal_faq_inner .MuiAccordionSummary-root:hover .MuiAccordionSummary-expandIconWrapper{ color: #fbaa1a;}
.legal_faq .legal_faq_inner.Mui-expanded .MuiAccordionSummary-root .MuiAccordionSummary-content p, .legal_faq .legal_faq_inner.Mui-expanded .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper{ color: #fbaa1a;}
.legal_faq .legal_faq_inner .legal_faq_content{ padding: 0 20px 20px;}
.legal_faq .legal_faq_inner .legal_faq_content p{ color: #1C407F;}

/*Product listing page css start*/
.productlist_wrap{ background-color: #eef7fe; padding: 32px 0;}
.productlist_wrap h1{ font-size: 30px; font-weight: 700;}
.breadcrumbs ol li a{ display: flex; color: #1C407F;}
.breadcrumbs ol li p{ color: #fbaa1a;}
.breadcrumbs ol li.MuiBreadcrumbs-separator{ margin: 0;}
.breadcrumbs ol li.MuiBreadcrumbs-separator svg{ color: #111;}

.product_row{ display: grid; gap: 2rem; grid-template-columns: repeat(4,minmax(0,1fr)); padding-top: 50px;}
.product_bx{ padding: 1rem; background-color: #fff; border-radius: 24px; display: flex; flex-direction: column; width: 100%; height: 100%; box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);}
.product_bx .product_img{ aspect-ratio: 1/0.8; object-fit: contain;}
.product_bx .flex-grow{ flex-grow: 1;}
.product_bx ul{ margin: 0; padding: 0 0 1rem; list-style: none;}
.product_bx ul li{ display: flex; align-items: center; gap: 1rem;}
.product_bx ul li svg{ min-width: 12px; width: 12px; color: #fbab1b;}
.product_bx ul li span{ overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1;}
.product_bx a{ text-decoration: none;}
.product_bx .button3{ min-width: 100%;}
.gam_txt{ font-size: 13px; display: flex; align-items: center; line-height: 1.2; gap: 4px; font-weight: 500; margin: -9px 0 15px;}

.progressOverlay{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0,0,0,0.7); z-index: 100; display: flex; align-items: center; justify-content: center;}
.progressOverlay .progressBx{ background: #fff; border-radius: 10px; width: 80px; height: 80px; text-align: center; display: flex; align-items: center; justify-content: center;}
.progressOverlay .progressBx span{ color: #1C407F;}

/*Blog listing page css start*/
.blog_welcome{ padding: 80px 0; text-align: center;}
.blog_welcome h1{ font-size: 40px; font-weight: 700; margin-bottom: 40px;}
.blog_welcome p{ font-size: 1.1667rem; max-width: 70%; margin: 0 auto;}
.new_posts{ padding: 80px 0;}
.new_posts h2{ font-size: 30px; font-weight: 700; text-align: center;}
.new_post_box{ display: grid; grid-template-columns: 1fr 1fr; gap: 60px; width: 100%; height: 100%; margin-top: 80px;}
.new_post_box .MuiGrid-item{ background-color: #fff; box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; height: 100%;}
.new_post_box .npImg img{ height: 100%; width: 100%; object-fit: cover; border-bottom: 4px solid #fbaa1a; min-height: 250px; aspect-ratio: 1/0.5;}
.new_post_box .npContent{ display: flex; flex-direction: column; align-items: flex-start; flex: 1; gap: 10px; padding: 16px;}
.new_post_box .npContent h3{ font-size: 1.4063rem; font-weight: 700; line-height: 1.6;}
.new_post_box .npContent p{ flex-grow: 1;}
.new_post_box .npContent .MuiButton-text{ padding: 0; font-size: 16px; font-weight: bold; text-transform: none; letter-spacing: 0; color: #1C407F; background: none;}

.blog_post_grid.new_post_box{ grid-template-columns: 1fr 1fr 1fr; gap: 20px;}
.bopc_wrap h2{ font-size: 30px; font-weight: 700; text-align: center;}
.bopc_wrap .navigation-wrapper{ padding: 60px 40px 0;}
.bopc_wrap .keen-slider{ position: static;}
.bopc_wrap .keen-slider .keen-slider__slide{ padding: 15px;}
.bopc_wrap .keen-slider .product_bx{ padding: 0; border-radius: 20px; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px; overflow: hidden;}
.bopc_wrap .keen-slider .product_bx img{ aspect-ratio: 1/0.7; width: 100%; object-fit: cover;}
.bopc_wrap .keen-slider .product_bx .flex-grow{ padding: 0 16px 16px;}
.bopc_wrap .keen-slider .product_bx .flex-grow h4{ font-size: 1.1667rem;}
.bopc_wrap .keen-slider .product_bx .flex-grow h4 a{ color: #1C407F; text-decoration: none;}
.bopc_wrap .keen-slider .product_bx ul{ padding: 8px 0;}
.bopc_wrap .keen-slider .product_bx ul li a{ display: flex; align-items: center; gap: 1rem; color: #1C407F; text-decoration: none; padding: 2px 0;}
.bopc_wrap .keen-slider .product_bx ul li a:hover{ color: #fbaa1a;}
.bopc_wrap .keen-slider .product_bx .MuiButton-text{ padding: 0 16px 16px; font-size: 16px; font-weight: bold; text-transform: none; letter-spacing: 0; color: #1C407F; background: none;}

.pagination_box{ padding-top: 60px; width: 100%; display: flex; justify-content: center;}
.pagination_box ul li .MuiPaginationItem-root{ background-color: #69727d; color: #fff;}
.pagination_box ul li .MuiPaginationItem-root.MuiPaginationItem-previousNext{ background-color: #0073aa;}
.pagination_box ul li .MuiPaginationItem-root.Mui-selected{ background-color: #fbaa1a;}

/*Blog Detail page css start*/
.blogDetails{ padding: 80px 0;}
.blogDetails .bdContent{ display: flex; flex-wrap: wrap; gap: 2rem; padding-right: 40px;}
.blogDetails .bdContent h1{ font-size: 27px; font-weight: 700;}
.blogDetails .bdContent h2{ font-size: 24px; font-weight: 700;}
.blogDetails .bdContent h3{ font-size: 20px; font-weight: 700;}
.blogDetails .bdContent .bdImage{ width: 100%;}
.blogDetails .bdContent .bdImage img{ width: 100%; border-radius: 10px;}

.blogDetails .bopc_wrap{ position: sticky; top: 0;}
.blogDetails .bopc_wrap h2{ font-size: 24px;}
.blogDetails .bopc_wrap .navigation-wrapper{ padding-top: 25px;}
.blogDetails .bopc_wrap .keen-slider .product_bx .MuiButton-text{ background: rgb(251, 170, 26); margin: 0 16px 16px; border-radius: 30px; width: 120px; padding: 8px;
text-align: center; justify-content: center; color: #fff; font-weight: 600; text-transform: uppercase;}

.register_today{ background: url(../public/images/footer-cta-bg.png) center center no-repeat; background-size: cover; padding: 40px; border-radius: 30px; text-align: center;}
.register_today h4{ font-size: 1.4063rem; font-weight: 700;}
.register_today p{ font-size: 1.1667rem;}
.register_today .button1{ min-width: 160px;}

/*Contact Us page css start*/
.contact_wrap{ padding: 80px 0;}
.contact_inner{ display: grid; grid-template-columns: 1fr 1fr; gap: 60px;}
.contact_content h1{ font-size: 40px; font-weight: 700;}
.contact_content p{ display: flex; align-items: center;}
.contact_content p svg{ margin-right: 10px; min-width: 18px;}
.contact_content p a{ color: #1C407F; text-decoration: none;}

.common_form{ gap: 1.5rem; display: flex; flex-wrap: wrap;}
.common_form .MuiTextField-root{ margin: 0;}
.common_form .MuiInputLabel-formControl{ color: rgb(15, 63, 131);}
.common_form .MuiInputLabel-formControl.Mui-focused{ color: rgb(15, 63, 131);}
.common_form .MuiInputBase-formControl{ background-color: rgb(249, 250, 250);}
.common_form .MuiInputBase-formControl input{ box-shadow: none;}
.common_form fieldset{ border-color: rgb(157, 177, 206);}
.common_form .MuiInputBase-formControl.Mui-focused fieldset{ border-color: #1C407F;}

/*my account pages css start*/
.dashboard_wrap{ max-width: 1300px !important; padding-top: 60px; padding-bottom: 60px;}
.dashboard_sidebar{ width: 25%;}
.dashboard_sidebar .backtoaccount a{ display: flex; align-items: center; gap: 10px; font-weight: 600; color: #1C407F; font-size: 1rem; text-decoration: underline;}
.dashboard_sidebar .backtoaccount a svg{ font-size: 24px;}
.dashboard_sidebar .profile_avatar{ display: flex; align-items: center; justify-content: center; flex-shrink: 0; font-size: 1.42857rem; border-radius: 50%; user-select: none; background-color: #1C407F; color: #fff; width: 58px; height: 58px;}
.dashboard_sidebar .profile_username{ color: #1C407F; font-weight: 700; text-transform: uppercase; font-size: 1.125rem;}
.dashboard_sidebar .profile_useremail{ color: #1C407F; font-size: 1rem; word-break: break-word;}
.dashboard_sidebar .logout_btn{ font-size: 1rem; color: #1C407F;}
.dashboard_sidebar .profile_address{ background-color: rgb(214, 235, 255); padding: 1rem; border-radius: 16px;}
.dashboard_sidebar .profile_address h6{ font-weight: 700; color: #1C407F; margin-bottom: 10px;}
.dashboard_sidebar .profile_address p{ color: #1C407F;}
.dashboard_sidebar .profile_address p svg{ vertical-align: middle; margin-left: -4px;}
.dashboard_sidebar .profile_address p a{ text-decoration: underline; font-weight: 600; color: rgb(18, 64, 131); font-size: 1rem;}
.dashboard_sidebar .chatnow_bx h6{ font-weight: 600; margin-bottom: 20px;}
.dashboard_sidebar .chatnow_bx .chatnow_btn{ background-color: #1C407F; width: 100%; border-radius: 30px; font-weight: bold; padding: 10px 20px; gap: 10px; font-size: 1rem;}
.dashboard_sidebar .chatnow_bx .chatnow_btn svg{ font-size: 20px;}
.dashboard_sidebar .chatnow_bx .chatnow_btn:hover{ background-color: rgb(11, 45, 101); box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;}

.dashboard_content{ width: 75%; padding-left: 30px;}
.dashboard_content h1{ font-size: 20px; color: #1C407F; font-weight: 700; letter-spacing: 0;}
.dashboard_content .address_box{ background-color: #fff; padding: 2rem; border-radius: 8px; margin: 16px 0 24px; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px; justify-content: space-between;}
.dashboard_content .address_box p{ color: #1C407F; letter-spacing: 0;}
.dashboard_content .address_box .address_action_btns{ display: flex; flex-flow: column wrap; align-items: flex-end;}
.dashboard_content .address_box .address_action_btns button, .dashboard_content .address_box .address_action_btns label{ font-weight: 500; font-size: 1rem; text-transform: none; padding: 0px; text-decoration: underline; color: #1C407F; margin: 0;}
.dashboard_content .address_box .address_action_btns button:hover, .dashboard_content .address_box .address_action_btns label:hover{ background: none; text-decoration: none;}
.dashboard_content .address_box .address_action_btns button svg{ margin-right: 5px; font-size: 20px;}
.dashboard_content .address_box .address_action_btns label span{ font-weight: 500; padding: 0;}
.dashboard_content .address_box .address_action_btns label span.MuiRadio-root{ margin-right: 7px;}
.dashboard_content .address_box .address_action_btns label span.MuiRadio-root svg{ width: 20px; color: #1C407F;}
.dashboard_content .moreaddress_form_btn button{ width: 100%; padding: 0; background: none;}
.dashboard_content .moreaddress_form_btn button svg{ width: 100%;}
.dashboard_content .moreaddress_form_btn button span{ display: none;}
.dashboard_content .address_addedit_form{ background-color: #fff; padding: 12px 32px 32px 12px; border-radius: 8px; margin: 16px 0 24px; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;}

/*Product detail page css start*/
.pd_slide_content .MuiContainer-root{ max-width: 1300px !important;}
.product_detail_slider{ position: relative; height: 500px;}
.product_detail_slider img{ width: 100%; height: 100%; object-fit: contain;}
.pd_content h1{ font-size: 2.4291rem; font-weight: 700;}
.pd_content .pd_price{ font-size: 1.6867rem; color: #fbaa1a; font-weight: 700; margin-top: 22px;}
.pd_content .pd_error_box{ width: fit-content; display: flex; align-items: center; gap: 10px; padding: 17px; background-color: #ffe071; color: #c67b22; margin-top: 20px;}
.pd_content .pd_error_box svg{ flex-shrink: 0;}
.qip_btns{ gap: 10px;}
.qip_btns .qip_btn{ background-color: #fff; margin: 0; border: 1px solid gray; text-transform: none; padding: 7px 15px; border-radius: 4px; transition: all 0.25s ease-in 0s;}
.qip_btns .qip_btn:hover{ box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px; border-color: #1C407F;}
.qip_btns .qip_btn.active{ border-color: #1C407F;}
.qip_btns .qip_btn.active:hover{ background-color: #1C407F; color: #fff;}
.qip_btns .qip_btn span{ font-weight: 500;}
.qip_btns .qip_btn span svg{ color: #1C407F;}
.qip_btns .qip_btn.active:hover span svg{ color: #fff;}

.pd_content .delete_btn{ width: 120px; border: 1px solid #1C407F; color: #1C407F; text-transform: none; background-color: #fff; font-size: 16px;}
.pd_content .delete_btn:hover{ background-color: #1C407F; color: #fff;}
.pd_content .pd_total_price{ font-size: 2.4291rem; font-weight: 700; margin: 2rem 0 3rem;}
.pd_content .pd_qty_row{ display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 1.3rem;}
.pd_content .pd_qty_row .pd_qty{ display: flex; align-items: center; gap: 10px;}
.pd_content .pd_qty_row .pd_qty svg{ cursor: pointer;}
.pd_content .pd_qty_row .pd_qty_fld input{ background-color: #fff; text-align: center; font-size: 18px; color: #1C407F; width: 75px; padding: 0; height: 56px;     line-height: 50px; font-weight: 500;}
.pd_content .pd_qty_row .pd_qty_fld fieldset{ border-color: black;}
.pd_content .pd_qty_row .button1{ margin-left: 20px;}
.pd_content .pd_qty_row .button1 svg{ margin-left: 10px; color: #1C407F;}
.pd_content .pd_qty_row .button1:hover svg{ color: #fbaa1a;}
.pd_content .prescription_fee{ border-top: 1px solid #1C407F; padding: 10px; margin-top: 20px;}
.pd_content .prescription_fee a{ padding: 20px 10px; text-decoration: none;}
.pd_content .prescription_fee a p{ font-size: 1.1667rem; font-weight: 700; color: #1C407F;}

.pdtbs_content .verticalStaticPageTabs{ overflow: visible;}
.pdtbs_content .verticalStaticPageTabs .MuiTabs-scroller{ overflow: visible !important;}
.pdPageContent{ margin-left: 30px;}
.pdtbs_content .pdtbs_title{ font-size: 1.4063rem; font-weight: 700; margin-bottom: 24px;}

.related_products{ padding: 80px 0;}
.related_products h2{ font-size: 30px; font-weight: 700; text-align: center;}
.related_products .navigation-wrapper{ padding: 60px 40px 0;}
.related_products .keen-slider{ position: static;}
.related_products .keen-slider .keen-slider__slide{ padding: 15px;}
.related_products .keen-slider .product_bx{ box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;}

/*Question cart page css start*/
.cartFaq_wrap{ gap: 30px; padding: 40px 80px;}
.cartFaq_logo a{ max-width: fit-content; height: 90px; display: block;}
.cartFaq_logo img{ height: 100%; width: 100%; object-fit: contain;}
.cartFaq_close svg{ font-size: 28px; color: #000;}
.cartFaq_form_container{ gap: 80px;}
.questionnaire_title h6{ font-size: 1.4063rem; padding: 30px 30px 30px 50px; display: flex; align-items: center; background-color: #def0ff; gap: 10px; border-radius: 10px 10px 0px 0px; font-weight: normal; color: #1C407F;}
.questionnaire_title svg{ font-size: 45px; color: #fbaa1a; flex-shrink: 0;}
.question_login{ background: #1C407F; padding: 20px 50px;}
.question_login p{ color: #fff; font-size: 1.07143rem;}
.qsnans_box{ background: #fff; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px; border-radius: 0px 0px 10px 10px; padding: 30px 50px;}
.disclaimer_box{ margin-bottom: 30px;}
.disclaimer_box h6{ font-size: 1.1667rem; font-weight: 700; color: #1C407F;}
.disclaimer_box p{ font-size: 1rem; color: #1C407F;}
.qsnans_form{ display: flex; flex-direction: column; gap: 30px;}
.qsnans_form .question_row{ display: flex; align-items: center; gap: 20px;}
.question_row .question_title{ display: flex; align-items: center; max-width: 65%; gap: 10px;}
.question_row .question_title .question_number{ display: flex; align-items: center; justify-content: center; width: 40px; height: 40px; font-size: 1.5rem; color: #fff; font-weight: 700; border-radius: 50%; background: #fbaa1a; flex-shrink: 0;}
.question_row .question_title p{ color: #1C407F; margin: 0;}
.question_row .question_control{ margin-left: auto;}
.question_row .question_control button{ background: #f6fbfe; color: #1C407F; padding: 11px 22px; text-transform: none; font-size: 1rem; border-color: #6f94c7;}
.question_row .question_control button:first-of-type{ border-radius: 10px 0px 0px 10px;}
.question_row .question_control button:last-of-type{ border-radius: 0px 10px 10px 0px; border-left: 0;}
.question_row .question_control button.Mui-selected{ background: #fbaa1a;}
.error_box{ display: flex; align-items: center; gap: 10px; padding: 17px; color: #fff; background-color: #fa3c6b;}
.error_box svg{ font-size: 28px;}
.error_box p{ font-size: 1rem;}
.custom_checkbox{ display: flex; align-items: center; margin-left: -12px;}
.custom_checkbox p{ font-size: 1rem; color: #1C407F;}
.custom_checkbox .MuiCheckbox-root{ color: #1C407F;}
.custom_checkbox .MuiCheckbox-root.Mui-checked{ color: #1C407F;}
.qsnSubmit_btn button{ border: 1px solid #1C407F; font-size: 1rem; box-shadow: none; text-transform: none; font-weight: normal; background: #fbaa1a; min-width: 140px;}
.qsnSubmit_btn button:hover{ background: #1C407F; box-shadow: none;}
.qsnans_form .checkboxFieldClass{ color: #1C407F; display: flex; margin-right: 0;}
.qsnans_form .checkboxFieldClass .MuiCheckbox-root{ color: #1C407F;}
.qsnans_form .additionQuestionText p{ margin: 0; color: #1C407F;}
.additionQuestions_box{ display: flex; flex-direction: column; gap: 10px;}
.innerQsn_row{ display: flex; align-items: center; gap: 10px; color: #1C407F;}
.innerQsn_row svg{ font-size: 45px; color: #fbaa1a; flex-shrink: 0;}
.innerQsn_row p{ margin: 0;}
.qsnans_form .cartQsnTxtFld .MuiInputBase-formControl{ border: 1px solid rgb(111, 148, 199); background: #fff; max-height: 35px; font-size: 0.857143rem; color: #1C407F;}
.qsnans_form .cartQsnTxtFld .MuiInputBase-formControl fieldset{ border: 0;}

.form_progress_tracker{ position: sticky; top: 30px;}
.completion_time_box{ padding: 20px 26px; border-radius: 10px; text-align: center; background-color: #dcf0ff;}
.completion_time_box h6{ font-size: 1.4063rem; color: #1C407F; font-weight: normal;}
.progress_bar_box{ display: flex; align-items: center; justify-content: center; position: relative; padding: 30px 0; background: #fff;}
.progress_bar_dotted{ position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; flex-direction: column; border: 2px dashed #fbaa1a; border-radius: 50%; width: 248px; height: 248px; margin: auto;}
.progress_bar_dotted h2{ font-size: 4.375rem; color: #fbaa1a; font-weight: 700;}
.progress_bar_dotted h6{ font-size: 1.4063rem; color: #fbaa1a; font-weight: 700; text-align: center;}

/*Question cart success page css start*/
.cartFaq_success{ width: 100%; display: flex; flex-direction: column; align-items: center; gap: 30px; padding-top: 40px; text-align: center;}
.cartFaq_success h3{ font-size: 1.4063rem; font-weight: 700; line-height: 1.6;}
.cartFaq_success p{ font-size: 1.1429rem; line-height: 1.75;}

/*Cart Drawer css start*/
.cartDrawer{ width: 100%; max-width: 500px; height: 100%; padding: 20px 30px; display: flex; flex-direction: column; gap: 20px; background-color: #eef7fe; overflow: hidden auto;}
.cartDrawer_close{ width: 100%; display: flex; align-items: center; justify-content: space-between; color: #1C407F;}
.cartDrawer_close button{ color: #1C407F; padding: 0; background: none !important;}
.cartDrawer_img{ width: 33.33%;}
.cartDrawer_img .cartDrawer_imgbx{ display: block; border: 1px solid rgb(204, 223, 238); position: relative; height: 100%;}
.cartDrawer_img .cartDrawer_imgbx img{ height: 100%; width: 100%; object-fit: contain;}
.cartDrawer_img .cartDrawer_imgbx button{ position: absolute; top: 0px; right: 0px; z-index: 2; background-color: rgb(238, 247, 254); transform: translate(50%, -50%);    color: #1C407F; padding: 0; font-size: 22px;}
.cartDrawer_img .cartDrawer_imgbx button:hover{ color: #fbaa1a;}
.cartDrawer_namebx{ width: 100%; display: flex; justify-content: space-between; gap: 5px;}
.cartDrawer_namebx a{ text-decoration: none;}
.cartDrawer_namebx .prdnameTitle{ color: #1C407F; font-size: 0.857143rem; display: block; font-weight: bold;}
.cartDrawer_namebx .prdPrice{ color: #1C407F; font-size: 0.857143rem; font-weight: bold; white-space: nowrap;}
p.prdnameSubTitle{ color: #1C407F; font-size: 0.857143rem;}
.cartDrawer_qty{ display: flex; justify-content: flex-start; align-items: center; margin-top: 10px; background-color: #fff; border-radius: 7px; border: 1px solid rgb(235, 233, 235); color: #1C407F; width: max-content;}
.cartDrawer_qty fieldset{ display: none;}
.cartDrawer_qty input{ width: 35px; font-size: 14px; padding: 0; text-align: center; height: auto; color: #1C407F; font-weight: 500;}
.cartDrawer_qty svg{ cursor: pointer;}

.cart_coupon_code{ display: flex; align-items: center; justify-content: space-between; min-height: 50px;}
.cart_coupon_code p{ color: #1C407F; font-weight: 700;}
.cart_coupon_code button{ color: #000; padding: 0; background: none !important;}
.cart_coupon_code button span{ display: none;}
.cart_coupon_code button svg{ font-size: 20px;}
.cart_coupon_code_form{ margin: 6px 0 16px; width: 100%; display: flex;}
.cart_coupon_code_form .MuiInputBase-formControl{ height: 100%; border-radius: 0;}
.cart_coupon_code_form input{ padding: 11px 14px; font-size: 0.857143rem; color: #1C407F; text-transform: uppercase; height: auto;}
.cart_coupon_code_form fieldset{ border-color: #1C407F !important; border-width: 1px !important; border-right: 0 !important;}
.cart_coupon_code_form button{ border: 0; background-color: #fbaa1a; color: #fff; border-radius: 0px; font-size: 0.857143rem; box-shadow: none;}
.cart_coupon_code_form button:hover{ background-color: #1C407F; box-shadow: none;}
.cart_shipment p{ display: flex; align-items: center; justify-content: space-between; color: #1C407F;}

.cart_empty{ display: flex; flex-direction: column; align-items: center; text-align: center; gap: 20px; padding-top: 20px;}
.cart_empty img{ width: 150px;}
.cart_empty h4{ font-size: 1.6867rem; font-weight: 700; color: #1C407F; line-height: 1.334; letter-spacing: 0;}
.cart_empty p{ color: #1C407F;}

/*Order list after login page css start*/
.orderList{ width: 100%; border-radius: 8px; padding: 30px 40px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 15px 0px;}
.orderList_details{ display: flex; justify-content: space-between;}
.orderList_content .orderId{ font-size: 1.1429rem; font-weight: 700; color: #1C407F; line-height: 1.75;}
.orderList_content .orderDate{ color: #1C407F; margin-bottom: 16px;}
.order_progress{ width: 100%; display: flex; gap: 0.5rem; flex-direction: column;}
.order_progress h6{ font-weight: 600; font-size: 1rem; color: #1C407F;}
.order_progress p{ font-size: 14px; color: #1C407F; text-transform: capitalize;}
.order_progress .MuiLinearProgress-bar{ background: #1C407F;}

.orderList_image{ display: flex; align-items: center; gap: 30px;}
.orderList_image .orderList_image_box{ display: flex; align-items: center; gap: 0.65rem;}
.orderList_image .orderList_image_box .orderList_img{ width: 120px; height: 120px; border: 0.15rem solid rgba(16, 63, 131, 0.3);}
.orderList_image .orderList_image_box .orderList_img img{ width: 100%; height: 100%; object-fit: contain;}
.orderList .view_order_btn{ border-radius: 30px; padding: 10px 20px; font-size: 1rem; background: #1C407F; display: block; text-align: center;}
.orderList .view_order_btn:hover{ background: #fbaa1a;}
.view_order_button_tablet{ display: none; padding-top: 30px;}

/*Order detail after login page css start*/
.order_summary_row{ display: flex; gap: 1rem; border-radius: 8px; margin: 1rem 0px; padding: 16px; background: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px;}
.order_summary_row .order_summary_image{ width: 120px; height: 120px; border: 0.15rem solid rgba(16, 63, 131, 0.3);}
.order_summary_row .order_summary_image img{ width: 100%; height: 100%; object-fit: contain;}
.order_summary_row .order_summary_content .os_name{ font-size: 1.1667rem; color: #1C407F; font-weight: 700;}
.order_summary_row .order_summary_content p{ color: #1C407F;}
.order_summary_row .order_summary_content p span{ font-weight: bold;}
.order_summary_row .order_summary_content .os_price{ color: #1C407F; font-size: 20px; font-weight: 700; margin-top: 0.5rem;}

.os_total{ width: 384px; margin-left: auto;}
.os_total .os_total_bx{ background: #d6ebff; padding: 2rem; border-radius: 5px; margin-bottom: 1rem;}
.os_total .os_total_bx p{ display: flex; align-items: center; justify-content: space-between; color: #000;}
.os_total .os_total_bx p span{ color: #1C407F; font-weight: 700;}
.os_total .os_appendix button{ width: 6rem; height: 6rem; background-color: rgb(245, 244, 244); cursor: pointer; padding: 1rem; border-radius: 0;}
.os_total .os_appendix button svg{ width: 50px; height: 50px;}

.dashboard_sidebar .profile_status{ background-color: #fff; padding: 2rem; border-radius: 16px;}
.od_status{ color: #1C407F; text-transform: uppercase; margin-top: 1rem;}

/*Order success page css start*/
.order_success_wrap{ display: flex; flex-wrap: wrap;}
.order_success_wrap .osLeft{ width: 50%; padding: 80px; color: #1C407F;}
.order_success_wrap .osRight{ width: 50%; padding: 80px; background: rgb(238, 247, 254);}
.order_nmbr{ display: flex; align-items: center; gap: 1rem; font-size: 20px;}
.order_nmbr svg{ width: 100px; height: 100px;}
.order_thankYou{ font-size: 24px; padding-top: 20px;}
.osDetail_bx{ border: 1px solid #d1d5db; border-radius: 10px; padding: 30px; margin-top: 30px;}
.osDetail_bx h3{ font-size: 24px;}
.osDetail_bx ul{ margin: 0; padding: 0 0 0 15px;}
.osDetail_bx .userOrderDetails{ width: 50%;}
.viewOrder_btn{ padding-top: 30px;}

.osProduct_row{ width: 100%; display: flex; flex-wrap: wrap; gap: 20px; border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding-bottom: 20px; margin-bottom: 20px;}
.osProduct_image{ width: 33.3333%;}
.osProduct_image .osProduct_imgbx{ display: block; border: 1px solid rgb(204, 223, 238); position: relative; height: 100%;}
.osProduct_image .osProduct_imgbx img{ height: 100%; width: 100%; object-fit: contain;}
.osProduct_image .osProduct_imgbx .quantity{ position: absolute; top: 0px; right: 0px; z-index: 2; background-color: #fbaa1a; transform: translate(50%, -50%); color:#fff; width: 20px; height: 20px; border-radius: 30px; display: flex; align-items: center; justify-content: center;}
.osProduct_content{ width: 58.3333%; margin-left: auto;}
.osProduct_name{ width: 100%; display: flex; justify-content: space-between; gap: 5px; margin-bottom: 4px;}
.osProduct_name a{ text-decoration: none;}
.osProduct_name .osProduct_title{ color: #1C407F; font-size: 0.857143rem; display: block; font-weight: bold;}
.osProduct_name .osProduct_price{ color: #1C407F; font-size: 0.857143rem; font-weight: bold; white-space: nowrap;}
.osProduct_content p{ color: #1C407F; font-size: 0.857143rem;}
.order_shipment p{ display: flex; align-items: center; justify-content: space-between; color: #1C407F; font-size: 1.07143rem;}

.orderChatnow{ padding: 30px; border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 10px; margin-top: 50px;}
.orderChatnow h6{ font-weight: 600; margin-bottom: 20px; color: #1C407F;}
.orderChatnow .chatnow_btn{ background-color: #1C407F; border-radius: 30px; font-weight: bold; padding: 12px 40px; gap: 10px; font-size: 18px;}
.orderChatnow .chatnow_btn svg{ font-size: 24px;}
.orderChatnow .chatnow_btn:hover{ background-color: rgb(11, 45, 101); box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;}

/*Checkout page css start*/
.checkout_wrap{ display: flex; flex-wrap: wrap;}
.mobile_checkout_logo{ display: none;}
.checkout_form{ width: 58.3333%; padding: 42px 10% 8%;}
.checkout_products{ width: 41.6667%; padding: 66px 7%; background: rgb(238, 247, 254);}

.checkout_logo{ padding-bottom: 20px; text-align: center;}
.checkout_logo img{ max-width: 256px;}
.checkout_login{ padding-bottom: 20px;}
.checkout_login_row{ display: flex; align-items: center; justify-content: space-between; gap: 20px; padding-bottom: 20px;}
.checkout_form .common_form .MuiInputBase-formControl{ background: #fff;}
.checkout_form .common_form .calendarIcon .MuiInputBase-formControl:after{ content: ""; background: #fff url(../public/images/calendar-icon.svg) right center no-repeat; position: absolute; right: 15px; width: 24px; height: 24px; pointer-events: none;}
.country_select{ display: flex; flex-direction: column;}
.input_container{ display: flex; gap: 20px;}
.address_container{ display: flex; gap: 20px;}
.address_container .input_field{ width: 25%;}
.address_container .input_field:first-of-type{ width: 75%;}
.shipping_radio_group{ width: 100%;}
.shipping_radio_group label{ width: 100%; border: 1px solid rgb(157, 177, 206); margin: 0; padding: 5px;}
.shipping_radio_group label .MuiRadio-root{ color: #1C407F;}

.payment_card_row{ gap: 10px;}
.payment_card{ display: flex; align-items: center; border: 1px solid rgb(235, 233, 235); border-radius: 1rem; padding: 0 8px 0 12px; transition: all 0.25s ease-in 0s;}
.payment_card:hover{ background-color: rgba(15, 63, 131, 0.04); border-color: #1C407F;}
.payment_card .iconify{ font-size: 30px; margin-right: 0.8rem; margin-left: 6px; color: #000; flex-shrink: 0;}
.payment_card label{ margin: 0; padding: 18px 0; width: 100%; display: flex; justify-content: space-between; flex-direction: row-reverse; align-items: center;}
.payment_card label .MuiFormControlLabel-label{ font-size: 1rem; font-weight: 500; text-transform: uppercase;}
.payment_card label .MuiRadio-root{ color: #1C407F;}

.checkout_terms{ padding: 20px 0;}
.checkout_terms label{ margin-right: 0; align-items: flex-start;}
.checkout_terms label .MuiCheckbox-root{ color: #1C407F; padding-top: 0;}
.checkout_terms label .MuiFormControlLabel-label{ font-size: 0.857143rem; font-family: "Poppins", sans-serif; letter-spacing: 0;}
.checkout_terms label .MuiFormControlLabel-label a{ font-weight: bold; color: #1C407F; text-decoration: none;}

/*New home page css start*/
.menu_mobile_icon{ display: none;}
.home-page{ background-image: url(../public/images/home-top-bg-shape.png); background-position: right top; background-repeat: no-repeat;}
.bestseller_prd{ padding: 100px 0;}
.common_heading h2{ font-size: 40px; font-weight: 600; letter-spacing: 0; text-transform: capitalize;}
.common_heading p{ font-size: 22px; font-weight: 600; letter-spacing: 0;}
.bestseller_prd .product_bx{ padding: 30px; box-shadow: none; border-radius: 10px;}
.bestseller_prd .arrow{ width: 70px; height: 70px; border: 1px solid #1C407F; border-radius: 50%; padding: 26px;}
.bestseller_prd .arrow.arrow--left{ left: -85px;}
.bestseller_prd .arrow.arrow--right{ right: -85px;}
.gettoKnow_wrap{ padding: 50px 0;}
.gettoKnow_inner{ align-items: center; flex-wrap: nowrap !important; gap: 50px;}
.gettoKnow_img img{ width: 100%; height: auto;}
.gettoKnow_content{ padding-left: 6%;}
.gettoKnow_content h2{ font-size: 40px; font-weight: 600; text-transform: capitalize; letter-spacing: 0; margin-bottom: 10px;}
.gettoKnow_content h5{ font-size: 22px; font-weight: 600; letter-spacing: 0; margin-bottom: 10px;}
.gettoKnow_content p{ font-size: 18px; letter-spacing: 0; margin-bottom: 15px;}
.annaWagner_bx{ border: 1px solid #F0AE43; border-radius: 10px; padding: 10px; max-width: 80%;}
.annaWagner_bx p{ font-size: 14px; margin: 7px 0 0;}
.simple_steps{ padding: 90px 0;}
.simple_steps_inner{ justify-content: space-between;}
.simple_steps .simple_steps_bx{ width: 369px; max-width: inherit; flex-basis: auto;}
.simple_steps_bx .ssbx{ display: flex; flex-direction: column; align-items: center; text-align: center;}
.simple_steps_bx .ssbx img{ border-radius: 10px; width: auto; height: auto;}
.simple_steps_bx .ssbx h2{ font-size: 22px; font-weight: 600; letter-spacing: 0; margin: 30px 0 7px;}
.simple_steps_bx .ssbx p{ font-size: 17px; letter-spacing: 0; line-height: 1.3;}
.step_number{ background: #F0AE43; border: 2px solid #E1F0FE; width: 40px; height: 40px; border-radius: 40px; font-size: 18px; font-weight: 600; color: #fff; display: flex; justify-content: center; align-items: center; margin-top: -20px;}
.simple_steps_btn{ margin-top: 40px; text-align: center;}

.category_wrap{ padding: 100px 0;}
.category_wrap .keen-slider__slide a{ display: block; position: relative;}
.category_wrap .keen-slider__slide img{ width: 100%; height: auto; border-radius: 10px;}
.category_wrap .keen-slider__slide .category_nameBtn{ position: absolute; bottom: 30px; left: 10px; right: 10px; text-align: center;}
.category_wrap .keen-slider__slide .category_nameBtn h3{ font-size: 24px; color: #fff; font-weight: 600; margin-bottom: 15px;}
.category_wrap .keen-slider__slide .category_nameBtn .shopnowBtn{ border: 1px solid #fff; width: 215px; line-height: 53px; display: inline-block; border-radius: 40px; font-size: 18px; color: #fff; font-weight: 600; text-transform: none;}
.category_wrap .keen-slider__slide .category_nameBtn .shopnowBtn:hover{ background-color: #fff; color: #1C407F;}

.category_wrap .arrow{ width: 70px; height: 70px; border: 1px solid #1C407F; border-radius: 50%; padding: 26px; top: -135px; transform: none;}
.category_wrap .arrow.arrow--left{ right: 110px; left: auto;}
.category_wrap .arrow.arrow--right{ right: 0;}
.consultDoctor_wrap{ padding: 7% 0; background-position: center center; background-repeat: no-repeat; background-size: cover;}
.consultDoctor_wrap h2{ font-size: 40px; color: #fff; font-weight: 600; text-transform: capitalize; margin-bottom: 10px;}
.consultDoctor_wrap h4{ font-size: 22px; color: #fff; font-weight: 600;}
.consultDoctor_wrap ul{ margin: 18px 0 0; padding: 0;}
.consultDoctor_wrap ul li{ padding: 0; color: #fff;}

.reviews_wrap{  padding: 0 0 100px;}
.reviews_wrap .reviews_header h2{ font-size: 40px; font-weight: 600; text-transform: capitalize; text-align: center; margin-bottom: 15px;}
.reviews_outer{ column-count: 3; gap: 24px; margin-top: 45px;}
.reviews_outer .reviews_bx{ background-color: #fff; border-radius: 10px; padding: 30px; break-inside: avoid; margin-bottom: 24px;}
.loadMore_btn{ background: linear-gradient(180deg, rgba(240, 247, 253, 0) 0%, #F0F7FD 19.39%); display: flex; justify-content: center; padding-top: 100px; margin-top: -140px !important; position: relative;}
.loadMore_btn button{ border: 1px solid #23C860; color: #23C860; border-radius: 40px; min-width: 340px; height: 55px; display: flex; justify-content: center; align-items: center; text-align: center; font-size: 17px; font-weight: 600; background: none; box-shadow: none; padding: 0; text-transform: none; letter-spacing: 0;}
.loadMore_btn button:hover{ background: #23C860; color: #fff; box-shadow: none;}

.dppt_inner{ align-items: center;}
.dppt_content h2{ font-size: 40px; font-weight: 600; text-transform: capitalize; letter-spacing: 0; margin-bottom: 10px; max-width: 85%;}
.dppt_content h4{ font-size: 22px; font-weight: 600; margin-bottom: 28px;}
.dppt_img img{ width: 100%; height: auto;}






/*Responsive css start*/
@media only screen and (max-width: 1650px) {

    .bestseller_prd .arrow{ width: 40px; height: 40px; padding: 13px; background-color: #fff;}
    .bestseller_prd .arrow.arrow--left{ left: -14px;}
    .bestseller_prd .arrow.arrow--right{ right: -14px;}
    .category_wrap .arrow.arrow--left{ right: 80px;}
}
@media only screen and (max-width: 1500px) {
    .login_content{ padding: 4rem;}
    .cartFaq_wrap{ padding: 40px;}
}
@media only screen and (max-width: 1439px) {
    .product_row{ grid-template-columns: repeat(3,minmax(0,1fr));}
    .questionnaire_title h6{ padding: 30px;}
    .question_login{ padding: 20px 30px;}
    .qsnans_box{ padding: 30px;}
    .orderList_image .view_order_btn{ display: none;}
    .view_order_button_tablet{ display: block;}
    .checkout_form{ padding: 40px;}
    .checkout_products{ padding: 40px;}
    .order_success_wrap .osLeft{ padding: 40px;}
    .order_success_wrap .osRight{ padding: 40px;}

    .gettoKnow_content{ padding-left: 0;}
}
@media only screen and (max-width: 1359px) {

    .category_wrap .arrow{ width: 40px; height: 40px; padding: 13px; background-color: #fff; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%);}
    .category_wrap .arrow.arrow--left{ left: -14px; right: auto;}
    .category_wrap .arrow.arrow--right{ right: -14px;}
    .dppt_content h2{ font-size: 36px;}
    .dppt_content h4{ font-size: 20px;}

}
@media only screen and (max-width: 1199px) {

    .home_services .MuiGrid-item{ max-width: calc(50% - 8px);}
    .login_content{ padding: 2rem;}
    .login_content h2{ font-size: 36px;}
    .blog_welcome h1{ font-size: 36px;}
    .blog_welcome p{ max-width: 100%;}
    .new_post_box{ gap: 30px;}
    .blog_post_grid.new_post_box{ grid-template-columns: 1fr 1fr;}
    .blogDetails .bdContent{ padding-right: 10px;}
    .contact_inner{ grid-template-columns: 1fr;}
    .dashboard_sidebar{ width: 30%;}
    .dashboard_content{ width: 70%; padding-left: 20px;}
    .cartFaq_form_container{ gap: 40px;}
    .checkout_wrap{ flex-direction: column-reverse;}
    .checkout_form{ width: 100%;}
    .checkout_products{ width: 100%;}
    .checkout_logo{ display: none;}
    .mobile_checkout_logo{ display: block; order: 1; text-align: center; padding: 15px 0;}
    .mobile_checkout_logo img{ max-width: 192px;}
    .order_success_wrap{ flex-direction: column-reverse; padding-top: 25px;}
    .order_success_wrap .osLeft{ width: 100%;}
    .order_success_wrap .osRight{ width: 100%;}

    .navbar{ padding: 15px 0;}
    .mainMenu .mainMenu_inner{ gap: 40px;}
    .top_bar .keen-slider__slide{ text-align: center;}
    .top_bar .MuiContainer-root{ padding: 15px 40px;}
    .top_bar .arrow{ width: 15px; height: 15px; fill: #fff;}
    .top_bar .arrow--left{ left: 35px;}
    .top_bar .arrow--right{ right: 35px;}
    .top_bar p{ font-size: 15px;}
    .ndBanner_content h1{ font-size: 28px;}
    .ndBanner_content h4{ font-size: 18px;}
    .ndBanner_content ul li svg{ font-size: 20px !important;}
    .ndBanner_content ul li span{ font-size: 16px !important;}
    button.button2, a.button2{ font-size: 16px;}
    button.button3, a.button3{ font-size: 16px; height: 50px;}
    .ndBanner_content p{ font-size: 12px;}
    .ndBanner_content .rating_star{ font-size: 14px;}
    .shipping_wrap{ padding: 15px 0;}
    .shipping_block{ gap: 20px; display: grid; grid-template-columns: 1fr 1fr;}
    .shipping_block .MuiGrid-item{ max-width: 100%;}
    .bestseller_prd{ padding: 60px 0;}
    .common_heading h2{ font-size: 30px;}
    .common_heading p{ font-size: 18px;}
    .gettoKnow_inner{ gap: 30px;}
    .gettoKnow_content h2{ font-size: 30px;}
    .gettoKnow_content h5{ font-size: 18px;}
    .gettoKnow_content p{ font-size: 16px;}
    .annaWagner_bx{ max-width: 100%;}
    .annaWagner_bx p{ font-size: 13px !important; margin: 5px 0 0;}
    .simple_steps{ padding: 60px 0;}
    .simple_steps .simple_steps_bx{ width: 32%; max-width: 100%;}
    .simple_steps_bx .ssbx h2{ font-size: 18px; margin-top: 15px;}
    .simple_steps_bx .ssbx p{ font-size: 16px;}
    .category_wrap{ padding: 60px 0;}
    .category_wrap .common_heading{ text-align: center;}
    .consultDoctor_wrap h2{ font-size: 30px;}
    .consultDoctor_wrap h4{ font-size: 18px;}
    .consultDoctor_wrap ul li svg{ font-size: 20px !important;}
    .consultDoctor_wrap ul li span{ font-size: 16px;}
    .reviews_wrap{ padding: 0 0 60px;}
    .reviews_wrap .reviews_header h2{ font-size: 30px;}
    .reviews_wrap .reviews_header p{ font-size: 18px;}
    .reviews_wrap .reviews_header .MuiRating-root{ font-size: 22px;}
    .reviews_outer .reviews_bx p{ font-size: 16px !important;}
    .reviews_outer .reviews_bx p.verifiedCustomer{ font-size: 11px !important;}
    .faq_home{ padding: 60px 0;}
    .faqHome_innerItem #panel2-header h5{ font-size: 16px;}
    .faq_home .faqBtn{ margin-top: 30px;}
    .dppt_content h2{ font-size: 25px;}
    .dppt_content h4{ font-size: 18px;}
    .dppt_content .MuiRating-root{ font-size: 14px;}
    .dppt_content p{ font-size: 12px;}

}
@media only screen and (max-width: 991px) {

    .header_end .account_icon.menu_icon{ display: none;}
    .search_bar form input{ padding-left: 13px;}
    /* .shipping_block{ padding-top: 20px;}
    .shipping_block .MuiGrid-item{ padding: 15px;} */
    .rating_block.MuiGrid-item{ max-width: 100%; padding: 40px 0;}
    .home_services .MuiGrid-item a img{ width: 32px;}
    .spc_wrap .spc_img img{ max-width: 100%;}
    .spc_wrap .spc_content{ padding-top: 30px;}
    /* .faq_home .faq_accordion{ padding-top: 50px; column-gap: 2rem;}
    .faq_home_items{ width: calc(50% - 16px);} */
    .ocs_title{ margin: 0 !important;}
    .customer_service{ padding-top: 0;}
    .customer_service .csb{ gap: 1.5rem;}
    .customer_service .customer_service_bx{ width: calc(50% - 12px);}

    .login_right{ padding: 40px 20px;}
    .product_row{ grid-template-columns: repeat(2,minmax(0,1fr));}
    .blog_welcome{ padding: 40px 0;}
    .blog_welcome h1{ font-size: 30px; margin-bottom: 20px;}
    .new_posts{ padding: 40px 0;}
    .new_posts h2{ font-size: 24px;}
    .new_post_box{ margin-top: 40px; grid-template-columns: 1fr;}
    .new_post_box .npContent h3{ font-size: 20px;}
    .bopc_wrap h2{ font-size: 24px;}
    .bopc_wrap .navigation-wrapper{ padding: 26px 30px 0;}
    .blog_post_grid.new_post_box { grid-template-columns: 1fr;}
    .blogDetails{ padding: 40px 0;}
    .blogDetails .bdContent{ padding: 0 0 50px; gap: 1.5rem;}
    .blogDetails .bdContent h1{ font-size: 24px;}
    .blogDetails .bdContent h2{ font-size: 20px;}
    .blogDetails .bdContent h3{ font-size: 18px;}
    .contact_wrap{ padding: 50px 0;}
    .contact_inner{ gap: 40px;}
    .contact_content h1{ font-size: 36px;}
    .pagination_box{ padding-top: 40px;}

    .dashboard_wrap{ padding-top: 40px; padding-bottom: 40px;}
    .dashboard_sidebar{ width: 100%;}
    .dashboard_content{ width: 100%; padding: 20px 0 0;}
    .dashboard_content .address_box .address_action_btns{ margin-top: 1rem; align-items: flex-start;}
    .product_detail_slider{ height: 400px;}
    .pd_content{ padding-top: 20px;}
    .pd_content h1{ font-size: 30px;}
    .pd_content .pd_price{ font-size: 24px;}
    .pd_content .pd_error_box{ width: 100%;}
    .pd_content .pd_total_price{ font-size: 30px;}
    .pdtbs_content .pdtbs_title{ text-align: center;}
    .pdPageContent{ margin: 20px 0 0;}
    .related_products{ padding: 40px 0;}
    .related_products h2{ font-size: 24px;}
    .related_products .navigation-wrapper{ padding: 26px 30px 0;}

    .cartFaq_wrap{ padding: 20px;}
    .questionnaire_title h6{ padding: 20px; font-size: 20px;}
    .question_login{ padding: 20px;}
    .qsnans_box{ padding: 20px;}
    .question_row .question_title{ max-width: 80%;}
    .completion_time_box{ padding: 20px;}
    .completion_time_box h6{ font-size: 20px;}
    .progress_bar_box{ padding: 20px 0;}

    .navbar{ position: relative;}
    .logo{ text-align: center;}
    .logo img{ max-width: 125px; height: auto;}
    .mainMenu{ display: none;}
    .menu_mobile_icon{ display: block; position: absolute; top: 0; bottom: 0; display: flex; align-items: center; width: 32px; cursor: pointer; z-index: 1;}
    .header_end{ position: absolute; right: 24px; z-index: 1;}
    .header_end .account_icon{ margin-left: 20px;}
    .search_bar .search_fld{ width: 75vw;}
    .result_box{ width: 75vw; top: 54px;}
    .top_bar .MuiContainer-root{ padding: 10px 40px;}
    .top_bar p{ font-size: 14px;}
    .top_bar .arrow--left{ left: 40px;}
    .top_bar .arrow--right{ right: 40px;}
    .ndBanner_content{ order: 2; padding: 30px 0 40px;}
    .nbBanner_img{ text-align: center; position: relative;}
    .nbBanner_img:after{ content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 30px; background: linear-gradient(180deg, rgba(240, 247, 253, 0) 0%, #F0F7FD 19.39%);}
    .nbBanner_img_col ul{ bottom: 14px; right: 15%; margin: auto; width: 300px;}
    .nbBanner_img_col ul li{ border-radius: 5px; font-size: 12px;}
    .nbBanner_img_col ul li span{ font-size: 25px;}
    .shipping_block .MuiGrid-item{ display: flex; flex-direction: column; align-items: center; text-align: center; font-size: 15px;}
    .shipping_block .MuiGrid-item span{ margin: 0 0 10px;}
    .gettoKnow_inner{ flex-wrap: wrap !important;}
    .simple_steps_inner{ flex-direction: column !important; align-items: center; gap: 30px;}
    .simple_steps .simple_steps_bx{ width: 100%;}
    .simple_steps_btn{ margin-top: 35px;}
    .reviews_outer{ column-count: 2; gap: 20px; margin-top: 35px;}
    .dppt_inner{ flex-direction: column-reverse !important;}
    .dppt_content{ padding: 7px 0 40px;}
    .dppt_img{ position: relative;}
    .dppt_img:after{ content: ""; position: absolute; left: 0; right: 0; bottom: 0; background: linear-gradient(180deg, rgba(240, 247, 253, 0) 0%, #F0F7FD 19.39%); height: 36px;}
    .footer_wrap .footer_logo{ order: 4; flex-basis: 100%; max-width: 100%;}
    .footer_wrap .footer_logo img{ max-width: 120px;}
    .footer_wrap .footer_col{ max-width: calc(50% - 16px); flex-basis: calc(50% - 16px);}
    .footer_brands{ justify-content: flex-start; margin-top: 0;}
    .partner_logo{ text-align: left;}
    .partner_logo img{ max-width: 100px; height: auto;}
    .footer_content{ padding: 20px 0;}
}
@media only screen and (max-width: 899px) {

    .legal_header h1{ font-size: 36px;}
    .legal_content .pageContent{ margin: 20px 0 0;}
    .legal_faq{ padding: 20px 0 0;}
    .result_box ul li a .result_viewbtn{ display: none;}
    .bopc_wrap .navigation-wrapper.blogSlider{ padding-bottom: 40px;}
    .bopc_wrap .navigation-wrapper.blogSlider .bsal{ top: auto; left: 45%; transform: none;}
    .bopc_wrap .navigation-wrapper.blogSlider .bsar{ top: auto; right: 45%; transform: none;}
    .blogDetails .bopc_wrap{ position: static;}
}
@media only screen and (max-width: 767px) {
    div.container{ padding: 0 16px;}
    .top_bar p{ font-size: 14px;}
    /* .header_end .account_icon{ font-size: 0;}
    .header_end .account_icon + .account_icon{ margin-left: 18px;} */
    .header_end .account_icon .cart_count{ width: 20px; height: 20px; font-size: 12px; right: -14px; top: -7px;}
    /* .logo{ padding-bottom: 16px;} */
    /* .shipping_block .MuiGrid-item{ padding: 10px;} */
    .rating_block.MuiGrid-item h1{ font-size: 20px;}
    .rating_block.MuiGrid-item p{ font-size: 14px;}
    .rating_block.MuiGrid-item{ padding-bottom: 5px;}
    .home_services .MuiGrid-item{ max-width: 100%;}
    .packaging_wrap{ padding: 50px 0;}
    .packaging_wrap .packaging_inner{ flex-wrap: wrap; gap: 3rem;}
    .packaging_content{ order: 2;}
    .faq_home{ padding: 50px 0;}
    .faq_home_title{ font-size: 24px !important;}
    .faq_home .faq_accordion{ padding-top: 30px;}
    .faq_home_items{ width: 100%;}
    .ocs_wrap { padding: 50px 0 40px;}
    .ocs_title{ font-size: 24px !important;}
    .ocs_wrap .navigation-wrapper{ padding: 30px 0;}
    .navigation-wrapper .arrow.arrow--left, .navigation-wrapper .arrow.arrow--right{ display: none;}
    .customer_service{ flex-wrap: wrap !important; padding-top: 10px;}
    .customer_service .csb{ padding-top: 0;}
    .customer_service .customer_service_bx{ background-color: #ddf0ff; padding: 8px; border-radius: 8px;}
    /* .footer_wrap{ text-align: center;}
    .footer_wrap .footer_logo{ max-width: 100%; flex-basis: 100%;}
    .footer_wrap .footer_col{ max-width: 100%; flex-basis: 100%;}
    .footer_wrap ul li{ justify-content: center;}
    .footer_wrap .footer_brands{ flex-wrap: wrap; justify-content: center;} */

    .product_row{ grid-template-columns: repeat(1,minmax(0,1fr)); padding-top: 15px;}
    .bopc_wrap .navigation-wrapper{ padding: 25px 0 0;}
    .register_today{ background-position: right;}
    .register_today h4{ font-size: 20px; line-height: normal;}
    .register_today p{ font-size: 18px;}
    .dashboard_content .address_box{ padding: 20px;}
    .dashboard_content .address_addedit_form{ padding: 0px 20px 20px 0;}
    .pd_content h1{ font-size: 27px;}
    .pd_content .pd_price{ font-size: 22px; margin-top: 20px;}
    .pd_content .pd_total_price{ font-size: 27px;}
    .pd_content .pd_qty_row{ flex-direction: column; gap: 20px; align-items: flex-start;}
    .pd_content .pd_qty_row .button1{ margin-left: 0; width: 100%;}
    .pd_content .prescription_fee a{ padding: 20px 0;}
    .related_products .navigation-wrapper{ padding: 25px 0 0;}

    .cartFaq_logo{ max-width: 50%;}
    .questionnaire_title h6{ flex-direction: column; font-weight: bold; text-align: center;}
    .qsnans_form .question_row{ flex-direction: column; gap: 15px; align-items: flex-start;}
    .question_row .question_title{ max-width: 100%;}
    .question_row .question_title .question_number{ width: 30px; height: 30px; font-size: 1rem; font-weight: normal;}
    .question_row .question_control{ margin-left: 0;}
    .cartFaq_success{ padding-top: 0;}
    .cartFaq_success h3{ font-size: 1.21429rem;}
    .cartFaq_success p{ font-size: 1.07143rem;}
    .orderList{ padding: 30px 20px;}
    .orderList_details{ flex-direction: column; gap: 1rem;}
    .orderList_content .orderId{ font-size: 1.07143rem;}
    .os_total{ width: 100%;}
    .order_summary_row .order_summary_content .os_name{ font-size: 1rem;}
    .order_summary_row .order_summary_content p{ font-size: 14px;}
    .order_summary_row .order_summary_content .os_price{ font-size: 18px;}

    .bopc_wrap .navigation-wrapper.blogSlider .bsal{ left: 40%;}
    .bopc_wrap .navigation-wrapper.blogSlider .bsar{ right: 40%;}
    .cart_empty h4{ font-size: 1.4993rem;}
    .checkout_products{ padding: 20px;}
    .checkout_form{ padding: 20px;}
    .input_container{ flex-wrap: wrap;}
    .address_container{ flex-wrap: wrap;}
    .address_container .input_field{ width: 100%;}
    .address_container .input_field:first-of-type{ width: 100%;}
    .order_success_wrap{ padding-top: 16px;}
    .order_success_wrap .osLeft{ padding: 30px 0;}
    .order_success_wrap .osRight{ padding: 20px;}
    .orderChatnow{ margin-top: 30px; padding: 20px;}
    .orderChatnow .chatnow_btn{ padding: 12px 28px; font-size: 16px;}
    .orderChatnow .chatnow_btn svg{ font-size: 20px;}
    .order_nmbr{ flex-direction: column; font-size: 18px;}
    .order_thankYou{ font-size: 20px; text-align: center; font-weight: 500;}
    .osDetail_bx{ padding: 20px; margin-top: 20px;}
    .osDetail_bx h3{ font-size: 20px; font-weight: 500;}
    .osDetail_bx .userOrderDetailsBx{ flex-wrap: wrap;}
    .osDetail_bx .userOrderDetails{ width: 100%;}
    .viewOrder_btn{ padding-top: 20px;}
    .breadcrumbs ol{ justify-content: center;}

    .ndBanner{ padding-top: 5px;}
    .nbBanner_img_col ul{ right: 0; left: 0; width: 100%; padding-right: 10px;}
    .nbBanner_img_col ul li{ font-size: 10px; width: 250px; float: right; background: rgba(35, 200, 96, 0.8);}
    .nbBanner_img_col ul li:last-child{ top: 20px; left: 0; float: left; background: rgba(234, 168, 59, 0.8);}
    .nbBanner_img img{ width: 100%; aspect-ratio: 1/0.9; object-fit: contain;}
    .nbBanner_img::after{ right: auto; height: 10px; width: 70%;}
    .shipping_block .MuiGrid-item span{ width: 48px; height: 48px; padding: 10px; margin: 0 0 5px;}
    .bestseller_prd{ padding: 40px 0;}
    .common_heading h2{ font-size: 25px;}
    .bestseller_prd .navigation-wrapper .arrow.arrow--left, .bestseller_prd .navigation-wrapper .arrow.arrow--right{ display: block; border: 1px solid #1C407F26;}
    .gettoKnow_content h2{ font-size: 25px;}
    .gettoKnow_wrap{ padding: 40px 0;}
    .simple_steps{ padding: 40px 0;}
    .category_wrap{ padding: 40px 0;}
    .category_wrap .navigation-wrapper .arrow.arrow--left, .category_wrap .navigation-wrapper .arrow.arrow--right{ display: block; border: 1px solid #1C407F26;}
    .category_wrap .keen-slider__slide .category_nameBtn .shopnowBtn{ line-height: 48px; font-size: 16px;}
    .consultDoctor_wrap{ background-image: url(../public/images/graphics/consult-doctor-img-mobile.png) !important; background-size: cover; background-position: center top; padding: 100% 0 7%;}
    .consultDoctor_wrap h2{ font-size: 25px;}
    .reviews_wrap{ padding: 0 0 40px;}
    .reviews_wrap .reviews_header h2{ font-size: 25px;}
    .reviews_wrap .reviews_header .MuiRating-root{ font-size: 13px;}
    .reviews_wrap .reviews_header p{ font-size: 11px;}
    .reviews_outer{ margin-top: 30px; column-count: 1;}
    .loadMore_btn button{ font-size: 16px; height: 50px;}
    .faq_home{ padding: 40px 0;}
    .faqHome_innerItem{ padding: 10px;}
    .faqHome_innerItem #panel2-header h5{ margin-left: 10px;}
    .faq_home_items .faqhome_content{ padding: 0 0 0 40px;}
    .faq_home .faqBtn a{ height: 50px; font-size: 16px; padding: 0 10px;}
    .dppt_content h4{ margin-bottom: 22px;}
    .loadMore_btn{ padding-top: 80px;}
}
@media only screen and (max-width: 599px) {

    /* .search_bar{ order: 4;}
    .logo img{ max-width: 150px;height: auto;} */
    .login_form_inner{ max-width: 100%;}
    .login_form_inner .firstName{ width: 100%;}
    .menuDrawer{ width: 100vw;}
    .legal_header h1{ font-size: 30px;}
    .legal_content{ padding: 40px 0;}
    .legal_content .pageContent{ padding: 20px;}
    .result_box ul li a .result_prdimg{ max-width: 80px;}
    .product_detail_slider{ height: 350px;}
    .cartDrawer{ padding: 20px;}
    .cart_empty h4{ font-size: 1.35714rem;}

    .header_end .account_icon{ margin-left: 10px;}
    .logo{ padding-right: 40px;}
    .home-page{ background-image: url(../public/images/home-top-bg-shape-mobile.png); background-size: contain;}
    .top_bar{ padding: 0 16px;}
    .top_bar .MuiContainer-root{ padding: 10px 20px;}
    .top_bar p{ font-size: 10px; white-space: nowrap;}
    .top_bar p svg{ margin-right: 3px; max-height: 13px;}
    .top_bar .arrow{ width: 10px; height: 10px;}
    .top_bar .arrow--left{ left: 25px;}
    .top_bar .arrow--right{ right: 25px;}
    .ssRatings p{ font-size: 11px;}
    .ssRatings .MuiRating-root{ font-size: 14px;}
    .dppt_content .MuiRating-root{ font-size: 13px;}
    .dppt_content p{ font-size: 11px;}
    .search_bar .search_fld{ right: -5px;}
}
  